import React from 'react'
import SEO from '../components/seo/seo'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="body-wrapper">
      <h1>Sorry!</h1>
      <p>The page you are looking for is not available for one of the following reasons:</p>
      <ul>
        <li>The link to this page may be incorrect or out of date.</li>
        <li>You may have bookmarked a page that has been moved.</li>
      </ul>
      <p>Please use the navigation menu on this page to choose a different destination.</p>
      <br></br>
    </div>
  </>
)

export default NotFoundPage
